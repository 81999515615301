const translation = {
  en: {
    bumper_back: 'Rear Bumper',
    bumper_front: 'Front Bumper',
    door_back_left: 'Rear Door Left',
    door_back_right: 'Rear Door Right',
    door_front_left: 'Front Door Left',
    door_front_right: 'Front Door Right',
    fender_back_left: 'Rear Fender Left',
    fender_back_right: 'Rear Fender Right',
    fender_front_left: 'Front Fender Left',
    fender_front_right: 'Front Fender Right',
    fog_light_back_left: 'Rear Fog Light Left',
    fog_light_back_right: 'Rear Fog Light Right',
    fog_light_front_left: 'Front Fog Light Left',
    fog_light_front_right: 'Front Fog Light Right',
    grill_low: 'Grill Low',
    grill_radiator: 'Radiator Grill',
    handle_back_left: 'Rear Handle Left',
    handle_back_right: 'Rear Handle Right',
    handle_front_left: 'Front Handle Left',
    handle_front_right: 'Front Handle Right',
    head_light_left: 'Head Light Left',
    head_light_right: 'Head Light Right',
    mirror_left: 'Mirror Left',
    mirror_right: 'Mirror Right',
    quarter_window_back_left: 'Rear Quarter Window Left',
    quarter_window_back_right: 'Rear Quarter Window Right',
    quarter_window_front_left: 'Front Quarter Window Left',
    quarter_window_front_right: 'Front Quarter Window Right',
    rocker_panel_left: 'Rocker Panel Left',
    rocker_panel_right: 'Rocker Panel Right',
    tail_light_left: 'Tail Light Left',
    tail_light_right: 'Tail Light Right',
    wheel_back_left: 'Rear Wheel Left',
    wheel_back_right: 'Rear Wheel Right',
    wheel_front_left: 'Front Wheel Left',
    wheel_front_right: 'Front Wheel Right',
    window_back_left: 'Rear Window Left',
    window_back_right: 'Rear Window Right',
    window_corner_left: 'Corner Window Left',
    window_corner_right: 'Corner Window Right',
    window_front_left: 'Front Window Left',
    window_front_right: 'Front Window Right',
    windshield_back: 'Windshield Back',
    windshield_front: 'Windshield Front',
    front_spoiler: 'Front Spoiler',
    rear_spoiler: 'Rear Spoiler',
    hood: 'Hood',
    petrol_door: 'Petrol Door',
    pillar: 'Pillar',
    roof: 'Roof',
    trunk: 'Trunk',
  },
  fr: {
    bumper_back: 'Pare-chocs arrière',
    bumper_front: 'Pare-chocs avant',
    door_back_right: 'Portière arrière droite',
    door_back_left: 'Portière arrière gauche',
    door_front_left: 'Portière avant droite',
    door_front_right: 'Portière avant gauche',
    fender_back_left: 'Aile arrière gauche',
    fender_back_right: 'Aile arrière droite',
    fender_front_left: 'Aile avant gauche',
    fender_front_right: 'Aile avant droite',
    fog_light_back_left: 'Phare anti-brouillard arrière gauche',
    fog_light_back_right: 'Phare anti-brouillard arrière droit',
    fog_light_front_left: 'Phare anti-brouillard avant gauche',
    fog_light_front_right: 'Phare anti-brouillard avant droit',
    grill_low: 'Grille bas',
    grill_radiator: 'Grille radiateur',
    handle_back_left: 'Poignée arrière gauche',
    handle_back_right: 'Poignée arrière droite',
    handle_front_left: 'Poignée avant gauche',
    handle_front_right: 'Poignée avant droite',
    head_light_left: 'Phare gauche',
    head_light_right: 'Phare droit',
    mirror_left: 'Rétroviseur gauche',
    mirror_right: 'Rétroviseur droit',
    quarter_window_back_left: 'Vitres latérale arrière gauche',
    quarter_window_back_right: 'Vitres latérale arrière droite',
    quarter_window_front_left: 'Vitres latérale avant gauche',
    quarter_window_front_right: 'Vitres latérale avant droite',
    rocker_panel_left: 'Bas de caisse gauche',
    rocker_panel_right: 'Bas de caisse droit',
    tail_light_left: 'Feu arrière gauche',
    tail_light_right: 'Feu arrière droite',
    wheel_back_left: 'Roue arrière gauche',
    wheel_back_right: 'Roue arrière droite',
    wheel_front_left: 'Roue avant gauche',
    wheel_front_right: 'Roue avant droite',
    window_back_left: 'Vitre arrière gauche',
    window_back_right: 'Vitre arrière droite',
    window_corner_left: 'Vitre d\'angle gauche',
    window_corner_right: 'Vitre d\'angle droite',
    window_front_left: 'Vitre avant gauche',
    window_front_right: 'Vitre avant droite',
    windshield_back: 'Pare-brise arrière',
    windshield_front: 'Pare-brise avant',
    front_spoiler: 'Aileron avant',
    rear_spoiler: 'Aileron arrière',
    hood: 'Capot',
    petrol_door: 'Trappe à essence',
    pillar: 'Carrosserie',
    roof: 'Toit',
    trunk: 'Coffre',
  },
};

export default translation;
